'use client'

import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import { X } from 'lucide-react'
import * as React from 'react'

import { cn } from '@/_lib/utils'
import { Button } from './button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from './collapsible'
import { Icons } from './icons'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 from-primary/70 to-primary/50 bg-gradient-to-b backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  'fixed overflow-hidden z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm'
      }
    },
    defaultVariants: {
      side: 'right'
    }
  }
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = 'right', className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className)}
      {...props}
    >
      {children}
      <SheetPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPortal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName

const sheetHeaderVariants = cva(
  'flex flex-col space-y-2 text-center sm:text-left',
  {
    variants: {
      background: {
        default: 'bg-[#f8f8fa]',
        muted: 'bg-muted text-muted-foreground',
        white: 'bg-white text-foreground'
      }
    },
    defaultVariants: {
      background: 'default'
    }
  }
)

const SheetHeader = ({
  className,
  background,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof sheetHeaderVariants>) => (
  <div
    className={cn(sheetHeaderVariants({ background }), className)}
    {...props}
  />
)
SheetHeader.displayName = 'SheetHeader'

const sheetFooterVariants = cva(
  'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
  {
    variants: {
      background: {
        muted: 'bg-muted text-muted-foreground',
        default: 'bg-background-grey',
        white: 'bg-white text-foreground'
      }
    },
    defaultVariants: {
      background: 'default'
    }
  }
)
const SheetFooter = ({
  className,
  background,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof sheetFooterVariants>) => (
  <div
    className={cn(sheetFooterVariants({ background }), className)}
    {...props}
  />
)
SheetFooter.displayName = 'SheetFooter'

interface SheetFooterCollapsibleProps {
  title: string
}

const SheetFooterCollapsible = ({
  className,
  background,
  children,
  title,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof sheetFooterVariants> &
  SheetFooterCollapsibleProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className={cn(sheetFooterVariants({ background }))} {...props}>
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className={className}>
        <CollapsibleTrigger asChild>
          <Button
            className="flex items-center justify-between px-6"
            variant="ghost"
            size="lg"
            full
          >
            <SheetSectionTitle>{title}</SheetSectionTitle>
            <Icons.ChevronDownIcon
              className={cn('transition h-5 w-5', {
                'rotate-180': isOpen
              })}
            />
            <span className="sr-only">Toggle</span>
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>{children}</CollapsibleContent>
      </Collapsible>
    </div>
  )
}
SheetFooterCollapsible.displayName = 'SheetFooterCollapsible'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold text-foreground capitalize',
      className
    )}
    {...props}
  />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

const sheetSectionVariants = cva('px-6 py-4', {
  variants: {
    border: {
      true: 'border-b-[1px] border-gray-200'
    },
    padding: {
      default: 'p-6',
      sm: 'p-4',
      md: 'p-6',
      lg: 'p-8'
    }
  },
  defaultVariants: {
    border: true,
    padding: 'default'
  }
})

const SheetSection = React.forwardRef<
  React.ElementRef<'section'>,
  React.ComponentPropsWithoutRef<'section'> &
    VariantProps<typeof sheetSectionVariants>
>(({ className, border, padding, ...props }, ref) => (
  <section
    ref={ref}
    className={cn(sheetSectionVariants({ border, padding }), className)}
    {...props}
  />
))
SheetSection.displayName = 'SheetSection'

const SheetSectionTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('text-base font-semibold text-foreground mb-2', className)}
    {...props}
  />
))
SheetTitle.displayName = 'SheetSectionTitle'

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  // Sections
  SheetFooterCollapsible,
  SheetHeader,
  SheetSection,
  SheetSectionTitle,
  SheetTitle,
  SheetTrigger
}
