import { components } from '@/_models'
import { z } from 'zod'

// ! This is ONLY for the edit dialogs as the questions are added as individual fields to the schema
// ! rather than as an array
export const generateCheckinQuestionSchema = (
  question: components['schemas']['VisitCheckinQuestionByUserTypeResponse']
) => {
  if (question.optional) {
    if (question.regex !== null) {
      return z.string().regex(new RegExp(question.regex)).optional()
    } else {
      return z.string().optional()
    }
  }

  if (question.regex !== null) {
    return z.string().regex(new RegExp(question.regex)).nonempty()
  }

  return z.string().nonempty()
}
